<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - サブ評価</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="selected_department_id"
                    :options="options_department"
                    :disabled="loading_count > 0"
                ></form-select>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else class="col-20">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-2"></th>
                        <th class="text-center col-6">サブ評価</th>
                        <th class="text-center col-4"></th>
                    </tr>
                </thead>
                <template v-if="detailed_ranks.length">
                    <draggable
                            v-model="detailed_ranks"
                            item-key="detailed_rank_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle">
                                    <form-input v-model="element.detailed_rank_name"/>
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input v-model="detailed_rank_to_create.detailed_rank_name" /></td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import DetailedRank from '@/models/entities/detailed-rank';
import Department from '@/models/entities/department';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'MasterDetailedRank',
    components: {
        draggable,
        InlineLoader,
        FormInput,
        FormSelect,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // 検索用
            options_department: [],
            selected_department_id: null,
            // ローディング
            loading_count: 0,
            // 表示データ
            detailed_ranks: [],
            // 新規
            detailed_rank_to_create: new DetailedRank(),
            // 削除
            detailed_rank_to_remove: null,
        }
    },
    mounted() {
        this.fetchDepartments();
    },
    methods: {
        fetchDepartments() {
            // // ローディング開始
            this.loading_count++;
            // option空に
            this.options_department.splice(0);

            // 事業部一覧取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    this.$router.push({name: 'MasterDepartment'});
                }

                // 初期値事業部セット
                this.selected_department_id = this.options_department[0].value;

                // サブ評価一覧取得
                this.search();
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // サブ評価取得
        search() {
            // 事業部セットされているか確認
            if (this.$helper.isBlank(this.selected_department_id)) {
                this.showErrorMessage('事業部を選択してください');
                return;
            }

            this.loading_count++;
            //表示データ初期化
            this.detailed_ranks.splice(0);

            this.$http.get('/master/detailed-rank', {
                params: {
                    department: this.selected_department_id
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.detailed_ranks.push(new DetailedRank(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        // 新規登録
        create() {
            // バリデーション
            if (this.$helper.isBlank(this.detailed_rank_to_create.detailed_rank_name)) {
                this.showErrorMessage('サブ評価を入力してください');
                return;
            }

            this.startScreenLoading();

            // 事業部セット
            this.detailed_rank_to_create.department_id = this.selected_department_id;

            this.$http.post('/master/detailed-rank', this.detailed_rank_to_create)
            .then(response => {
                this.detailed_ranks.push(new DetailedRank(response.data));
                this.detailed_rank_to_create = new DetailedRank();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 保存（編集）
        update(detailed_rank) {
            // バリデーション
            if (this.$helper.isBlank(detailed_rank.detailed_rank_name)) {
                this.showErrorMessage('サブ評価を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/detailed-rank/${detailed_rank.detailed_rank_id}`, detailed_rank)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 削除モーダル
        removeReady(detailed_rank) {
            this.detailed_rank_to_remove = detailed_rank;
            this.$refs.confirmRemove.show();
        },
        // 削除処理
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/detailed-rank/${this.detailed_rank_to_remove.detailed_rank_id}`)
            .then(() => {
                const remove_index = this.detailed_ranks.findIndex((row) => row.detailed_rank_id === this.detailed_rank_to_remove.detailed_rank_id);
                this.detailed_ranks.splice(remove_index, 1);
                this.detailed_rank_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 並び順更新
        sort() {
            this.startScreenLoading();

            const detailed_ranks = this.detailed_ranks.map(detailed_rank_object => detailed_rank_object.detailed_rank_id);

            this.$http.post('/master/detailed-rank/sort', {
                detailed_ranks: detailed_ranks,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
